import React, { useState, useEffect, useRef, Fragment } from "react";
import { useLocation } from "@reach/router";
import axios from "axios";
import Pagination from "react-bootstrap/Pagination";
import { StaticImage } from "gatsby-plugin-image";

//styles
import styles from "./search.module.scss";
import "../../components/header/header.bootstrap.scss";

// Components
import SecondaryLanding from "../../layouts/secondary-landing";
import Breadcrumb from "../../components/breadcrumb/breadcrumb";
import SEO from "../../components/seo/seo";
import Icon from "../../components/custom-widgets/icon";
import SearchSuggestionsInput from "../../components/custom-widgets/search-suggestions-input";
import NotificationAlert from "../../components/notifications/notification-alert";

// Null import when ssr build is done, it fixes the module error trying to access window object.
const AddSearchClient = typeof window !== `undefined` ? require("addsearch-js-client") : null;

const Search = () => {
  const searchParams = new URLSearchParams(useLocation().search).get("addsearch");
  const [data, setData] = useState({ hits: [] });
  const [query, setQuery] = useState("");

  const [querySuggestions, setQuerySuggestions] = useState({ hits: [] });
  const [search, setSearch] = useState("");
  const [pageNo, setPageNo] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);

  const searchParamsRef = useRef(searchParams);
  const apiUrl = "https://api.addsearch.com/v1/search/";
  const itemsPerPage = 20;
  const PUBLIC_API_TOKEN = "32c2d1fdc8fe47dfd59c772e4a8a9263";
  if (searchParamsRef.current !== searchParams) {
    setSearch(searchParams);
    setQuery(searchParams);
    searchParamsRef.current = searchParams;
  }
  const fetchData = async () => {
    try {
      const result = await axios(`${apiUrl}${PUBLIC_API_TOKEN}?limit=${itemsPerPage}&term=${search}&page=${pageNo}`);
      setData(result.data);
      setIsLoading(false);
    } catch (error) {
      setIsError(true);
    }
  };

  const locationParams = useLocation();

  useEffect(() => {
    if (search !== "") {
      setData({ hits: [] });
      setIsLoading(true);
      fetchData();
      window.history.replaceState(null, "", `${locationParams.pathname}?addsearch=${search}`);
    }
  }, [search, pageNo]);

  useEffect(() => {
    if (searchParams) {
      setSearch(searchParams);
      setQuery(searchParams);
      addSearchClient.search(searchParams, addSearchCallback);
    }
  }, []);

  // Create client with your 32-character SITEKEY
  // Null client when SSR build is done, it fixes the error coming from using a null import
  const addSearchClient = AddSearchClient ? new AddSearchClient(PUBLIC_API_TOKEN) : null;

  const addSearchCallback = (res) => {
    setQuerySuggestions(res);
  };
  const SEOData = {
    title: "Search",
    meta: [
      {
        name: "robots",
        content: "noindex, nofollow"
      }
    ]
  };
  const breadcrumbData = [
    {
      id: 1,
      active: true,
      title: "Search"
    }
  ];

  let items = [];
  const n = Math.ceil(data.total_hits / itemsPerPage);
  if (n) {
    for (let number = 1; number <= n; number++) {
      items.push(
        <Pagination.Item
          key={number}
          href="#addSearchTextBox"
          onClick={() => setPageNo(number)}
          active={number === pageNo}
        >
          {number}
        </Pagination.Item>
      );
    }
  }

  return (
    <SecondaryLanding>
      <SEO {...SEOData} />
      <Breadcrumb data={breadcrumbData} />
      <section className="container">
        <SearchSuggestionsInput
          type="widget"
          query={query}
          setQuery={setQuery}
          querySuggestions={querySuggestions}
          setQuerySuggestions={setQuerySuggestions}
          search={search}
          setSearch={setSearch}
        />

        {isError && (
          <NotificationAlert
            type="warning"
            id="addsearch-error-notification-alert"
            bodyText="Something went wrong ..."
          />
        )}
        {isLoading ? (
          <p className="h3">Loading results ...</p>
        ) : (
          <>
            {data.hits.length ? (
              <>
                <div className="my-3 text-larger">
                  {`${data.total_hits} results for `}
                  <strong>{search}</strong>
                </div>
                {data.hits.map((hit, index) => (
                  <div
                    key={hit.id}
                    className={`${
                      index === data.hits.length - 1 ? "border-bottom" : ""
                    } border-top row p-3 align-items-center ${styles.searchResult}`}
                  >
                    <div className="d-none d-md-block col-md-3 col-lg-2">
                      {hit.document_type === "pdf" ? (
                        <StaticImage
                          src="../../images/employees/branch-managers/wafd-bank-branch-manager-default.png"
                          alt=""
                          placeholder="blurred"
                          quality="100"
                          loading="eager"
                          style={{ width: "100px" }}
                        />
                      ) : (
                        <img alt="" src={hit.images.main} className="w-100" />
                      )}
                    </div>
                    <a href={hit.url} className="text-decoration-none col-md-9 col-lg-10 text-black">
                      <span className="lead d-block text-success">{hit.title}</span>
                      {hit.document_type === "pdf" ? (
                        <>
                          <span className="my-2 text-sm d-flex">
                            <Icon name="file-pdf" class="mr-2 mt-1" />
                            {hit.url}
                          </span>{" "}
                        </>
                      ) : (
                        <>
                          <span className="my-2 text-sm d-block">{hit.url}</span>
                        </>
                      )}
                      <span>{hit.meta_description}</span>
                    </a>
                  </div>
                ))}
                <Pagination className={`mt-4 flex-wrap ${styles.pagination}`}>{items}</Pagination>
              </>
            ) : search ? (
              <p className="h3">No results found</p>
            ) : null}
          </>
        )}
      </section>
    </SecondaryLanding>
  );
};
export default Search;
